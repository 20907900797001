const breadcrumb = null;
// Global - Used for document.title - App.vue
// {
//   id: null,
//   title: null
// }

const modules = [];

// TODO: loading_navigations
const navigations = [
   // 0
  {
    module: "Job Posting",
    show: false, // TODO
    required_modules: [
      "HR_JOB_REQUISITION",
      "HR_JOB_REQUISITION_APPLICATIONS",
      "HR_JOB_REQUISITION_MASTERLIST",
    ],
  },

   // 1
  {
    module: "Leave Management",
    show: false, // TODO
    required_modules: ["HR_LEAVE_MANAGEMENT", "HR_LEAVE_CARD"],
  },

  // 2
  {
    module: "Overtime Management",
    show: false, // TODO
    required_modules: ["HR_CTO_MANAGEMENT"],
  },

  // 3
  {
    module: "Course Management",
    show: false, // TODO
    required_modules: ["HR_COURSE_MANAGEMENT", "HR_COURSE_MANAGEMENT_SUB"],
  },
];

export default {
  breadcrumb,
  modules,
  navigations,
};
