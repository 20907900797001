const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../../pages/public/Login"),
  },
  {
    path: "/human-resource",
    name: "Human Resource",
    component: () => import("../../pages/main/HumanResourceIndex"),
    meta: {
      auth: true,
    },
    children: [
      {
        name: "Job Posting Index",
        path: "job-posting",
        component: () => import("../../components/JobPosting/Index"),
        children: [
          {
            name: "Job Posting Datatable",
            path: "datatable",
            meta: {
              module: "Job Posting",
              component: "Datatable",
            },
            component: () => import("../../components/JobPosting/Datatable"),
          },
          {
            name: "Job Posting Form",
            path: "form",
            meta: {
              module: "Job Posting",
              component: "Form",
              breadcrumb: {
                parent_name: "Job Posting Datatable",
                id: "job_posting_id",
              },
            },
            component: () => import("../../components/JobPosting/Form"),
          },
          {
            name: "Job Posting Masterlist",
            path: "masterlist",
            meta: {
              module: "Job Posting",
              component: "Masterlist",
              breadcrumb: {
                parent_name: "Job Posting Datatable",
              },
            },
            component: () => import("../../components/JobPosting/Masterlist"),
          },
          {
            name: "Job Posting Masterlist Summary",
            path: "masterlist-summary",
            meta: {
              module: "Job Posting",
              component: "Masterlist Summary",
              breadcrumb: {
                parent_name: "Job Posting Masterlist",
              },
            },
            component: () =>
              import("../../components/JobPosting/MasterlistSummary.vue"),
          },
          {
            name: "Job Posting Masterlist Summary Applicant Profile",
            path: "applicant-profile",
            meta: {
              module: "Job Posting",
              component: "Applicant Profile",
              breadcrumb: {
                parent_name: "Job Posting Masterlist Summary",
                id: "user_id",
              },
            },
            component: () =>
              import("../../components/JobPosting/ApplicantProfile.vue"),
          },
          {
            name: "Job Posting Manage Applicants",
            path: "manage-applicants",
            meta: {
              module: "Job Posting",
              component: "Manage Applicants",
              breadcrumb: {
                parent_name: "Job Posting Datatable",
                id: "job_posting_id",
              },
            },
            component: () =>
              import("../../components/JobPosting/ManageApplicants.vue"),
          },
          {
            name: "Job Posting Applicant Regret Letter",
            path: "applicant-regret-letter",
            meta: {
              module: "Job Posting",
              component: "Applicant Regret Letter",
              breadcrumb: {
                parent_name: "Job Posting Manage Applicants",
                id: "application_id",
              },
            },
            component: () =>
              import(
                "../../components/JobPosting/ApplicantRegretLetter.vue"
              ),
          },
          {
            name: "Job Posting Applicant Duplicate Application",
            path: "applicant-duplicate-application",
            meta: {
              module: "Job Posting",
              component: "Duplicate Application",
            },
            component: () =>
              import("../../components/JobPosting/DuplicateApplication.vue"),
          },
          {
            name: "Job Posting Applicant Assign Appointee",
            path: "applicant-assign-appointee",
            meta: {
              module: "Job Posting",
              component: "Assign Appointee",
            },
            component: () =>
              import("../../components/JobPosting/AssignAppointee.vue"),
          },
        ],
      },
      {
        name: "Leave Management Index",
        path: "leave-management",
        component: () => import("../../components/LeaveManagement/Index"),
        children: [
          {
            name: "Leave Management Datatable",
            path: "datatable",
            meta: {
              module: "Leave Management",
              component: "Datatable",
            },
            component: () =>
              import("../../components/LeaveManagement/Datatable"),
          },
          {
            name: "Leave Management Form",
            path: "leave-management-form",
            meta: {
              module: "Leave Management",
              component: "Form",
              breadcrumb: {
                parent_name: "Leave Management Datatable",
                id: "leave_credit_header_id",
              },
            },
            component: () =>
              import("../../components/LeaveManagement/LeaveManagementForm"),
          },
          {
            name: "Leave Card Form",
            path: "leave-card-form/:employee_id",
            meta: {
              module: "Leave Management",
              component: "Leave Card Form",
              breadcrumb: {
                parent_name: "Leave Management Datatable",
                id: "hatodg",
              },
            },
            component: () =>
              import("../../components/LeaveManagement/LeaveCardForm"),
          },
          {
            name: "Leave Card Detail Form",
            path: "leave-card-detail-form",
            meta: {
              module: "Leave Management",
              component: "Form",
            },
            component: () =>
              import("../../components/LeaveManagement/LeaveCardDetailForm"),
          },
          {
            name: "Leave Card Sync Leave Transactions Form",
            path: "leave-card-sync-leave-transactions-form",
            meta: {
              module: "Leave Management",
              component: "Form",
            },
            component: () =>
              import(
                "../../components/LeaveManagement/LeaveCardSyncLeaveTransactionsForm"
              ),
          },
          {
            name: "Leave Card Many Sync Leave Transactions Form",
            path: "leave-card-many-sync-leave-transactions-form",
            meta: {
              module: "Leave Management",
              component: "Leave Card Sync Leave Transactions",
              breadcrumb: {
                parent_name: "Leave Management Datatable",
              },
            },
            component: () =>
              import(
                "../../components/LeaveManagement/LeaveCardManySyncLeaveTransactionsForm"
              ),
          },
          {
            name: "Leave Card Form Processes",
            path: "leave-card-form-processes",
            meta: {
              module: "Leave Management",
              component: "Leave Card Manual Processes",
              breadcrumb: {
                parent_name: "Leave Management Datatable",
                id: "hatodg",
              },
            },
            component: () =>
              import(
                "../../components/LeaveManagement/BackgroundProcesses/LeaveCardFormProcesses"
              ),
          },
          {
            name: "Leave Card Reports",
            path: "leave-card-reports",
            meta: {
              module: "Leave Management",
              component: "Leave Card Reports",
              breadcrumb: {
                parent_name: "Leave Management Datatable",
              },
            },
            component: () =>
              import(
                "../../components/LeaveManagement/Reports"
              ),
          },
        ],
      },
      {
        name: "Overtime Management Index",
        path: "overtime-management",
        component: () => import("../../components/Overtime/Index"),
        children: [
          {
            name: "Overtime Management Datatable",
            path: "datatable",
            meta: {
              module: "Overtime Management",
              component: "Datatable",
            },
            component: () => import("../../components/Overtime/Datatable"),
          },
          {
            name: "Overtime Monitoring Sheet",
            path: "overtime-monitoring-sheet",
            meta: {
              module: "Overtime Management",
              component: "Form",
              breadcrumb: {
                parent_name: "Overtime Management Datatable",
                id: "employee_id",
              },
            },
            component: () =>
              import("../../components/Overtime/OvertimeMonitoringSheet"),
          },
          // {
          //   name: "Leave Card Form",
          //   path: "leave-card-form/:employee_id",
          //   meta: {
          //     module: "Leave Management",
          //     component: "Leave Card Form",
          //     breadcrumb: {
          //       parent_name: "Leave Management Datatable",
          //       id: 'hatodg'
          //     },
          //   },
          //   component: () => import("../../components/LeaveManagement/LeaveCardForm"),
          // },
          // {
          //   name: "Leave Card Detail Form",
          //   path: "leave-card-detail-form",
          //   meta: {
          //     module: "Leave Management",
          //     component: "Form",
          //   },
          //   component: () => import("../../components/LeaveManagement/LeaveCardDetailForm"),
          // },
          // {
          //   name: "Leave Card Sync Leave Transactions Form",
          //   path: "leave-card-sync-leave-transactions-form",
          //   meta: {
          //     module: "Leave Management",
          //     component: "Form",
          //   },
          //   component: () => import("../../components/LeaveManagement/LeaveCardSyncLeaveTransactionsForm"),
          // },
          // {
          //   name: "Leave Card Many Sync Leave Transactions Form",
          //   path: "leave-card-many-sync-leave-transactions-form",
          //   meta: {
          //     module: "Leave Management",
          //     component: "Leave Card Sync Leave Transactions",
          //     breadcrumb: {
          //       parent_name: "Leave Management Datatable",
          //     },
          //   },
          //   component: () => import("../../components/LeaveManagement/LeaveCardManySyncLeaveTransactionsForm"),
          // },
        ],
      },
      // {
      //   name: "Training Database Index",
      //   path: "training-database",
      //   component: () => import("../../components/TrainingDatabase/Index"),
      //   children: [
      //     {
      //       name: "Training Database Datatable",
      //       path: "datatable",
      //       meta: {
      //         module: "Training Database",
      //         component: "Datatable",
      //       },
      //       component: () => import("../../components/TrainingDatabase/Datatable"),
      //     },
      //     {
      //       name: "Training Database Form",
      //       path: "form",
      //       meta: {
      //         module: "Training Database",
      //         component: "Form",
      //         breadcrumb: {
      //           parent_name: "Training Database Datatable",
      //           id: "training_id",
      //         },
      //       },
      //       component: () => import("../../components/TrainingDatabase/Form"),
      //     },
      //   ],
      // },
      {
        name: "Course Management Index",
        path: "course-management",
        component: () => import("../../components/TrainingDatabase/CourseManagement/Index"),
        children: [
          {
            name: "Course Management Datatable",
            path: "datatable",
            meta: {
              module: "Course Management - In-house Training",
              component: "Datatable",
            },
            component: () => import("../../components/TrainingDatabase/CourseManagement/Datatable"),
          },
          {
            name: "Course Management Form",
            path: "form",
            meta: {
              module: "Course Management",
              component: "Form",
              breadcrumb: {
                parent_name: "Course Management Datatable",
                id: "course_id",
              },
            },
            component: () => import("../../components/TrainingDatabase/CourseManagement/Form"),
          },
          {
            name: "Enroll Participants",
            path: "enroll-participants",
            meta: {
              module: "Course Management",
              component: "Enroll Participant",
              breadcrumb: {
                parent_name: "Course Management Datatable",
                id: "course_id",
              },
            },
            component: () => import("../../components/TrainingDatabase/CourseManagement/EnrollParticipant"),
          },
          {
            name: "Checklist",
            path: "checklist",
            meta: {
              module: "Course Management",
              component: "Checklist",
              breadcrumb: {
                parent_name: "Course Management Datatable",
                id: "course_id",
              },
            },
            component: () => import("../../components/TrainingDatabase/CourseManagement/Checklist/Index"),
          },
          {
            name: "Generate Certificate",
            path: "generatecertificate",
            meta: {
              module: "Course Management",
              component: "Generate Certificate",
              breadcrumb: {
                parent_name: "Checklist",
                id: "course_id",
              },
            },
            component: () => import("../../components/TrainingDatabase/CourseManagement/Checklist/GenerateCertificate"),
          },
          {
            name: "Generate Report Form",
            path: "generate-report",
            meta: {
              module: "Course Management",
              component: "Generate Report Form",
              breadcrumb: {
                parent_name: "Course Management Datatable",
              },
            },
            component: () => import("../../components/TrainingDatabase/CourseManagement/GenerateReport/Form"),
          },
        ],
      },
    ],
  },
  {
    path: "/*",
    component: () => import("../../pages/main/Index"),
  },
];

export default routes;
